import Paw from "../images/paw.png";
import React from "react";
import { Link } from "gatsby";

const SideNav = ({ data, records }) => {
  console.log("sidenav" , data )
  return (
    <div className="sidenav_main_container">
      {/* side_nav_main_div */}
      <div className="side_nav_main_div ">
        {/* side_nav_content_main_div */}
        <div className=" side_nav_content_main_div ">
          {/* image_div */}
          <div className="image_div">
            <Link to={"/"}>
              <img src={Paw} alt="" />
            </Link>
          </div>
          {/* head_div */}
          <div className="head_div ">
            <h3>カテゴリー</h3>
          </div>
          {/* categories_button_div */}
          {data?.allStrapiCategory?.edges?.map((single) => (
            <div className=" categories_button_div ">
              <Link
                to={`/category/${single?.node?.slug}`}
                className="button_container"
              >
                <button>{single?.node?.viewName}</button>
              </Link>
            </div>
          ))}
          {/* Famous_heading_div */}
          <div className="Famous_heading_div">
            <h3>人気記事</h3>
          </div>
          {/* card_div */}
          {[...records?.allStrapiPost?.edges]
            ?.splice(0, 5)
            ?.map((item, index) => (
              <div className="card_div ">
                <h3>No.{index + 1}</h3>
                <div className="content_main_div">
                  <div className="Thumbnail_heading_div">
                    <img src={item?.node?.thumbnail} alt="thumbnail"/>
                  </div>
                  <div className="title_heading_div">
                    <Link to={`/article/${item?.node?.slug}`}>
                      <p>{item?.node?.title}</p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          {/* Ads_main_dv */}
          {data?.allFeedGatsbyBlog?.edges?.slice(8, 10).map((single) => (
            <div className=" Ads_main_dv ">
              {/* common_box_Ads_main_dv  */}
              <a href={single?.node?.link} target="_blank" className="">
                <div
                  className="common_box_Ads_main_dv3 "
                  style={{
                    backgroundImage:
                      "url(" +
                      single?.node?.contentSnippet?.split("\n")[0] +
                      ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div className="common_transparent_color">
                    <p>{single?.node?.title}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideNav;
