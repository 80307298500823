import React from "react";
import { Link } from "gatsby";

const Footer = ({ data }) => {
  return (
    <div>
      {/* footer_main_div */}
      <div className="footer_main_div ">
        {/* text_div */}
        <div className="text_div ">
          {data?.allStrapiCategory?.edges?.map((single) => (
            <div className=" categories_button_div ">
              <Link
                to={`/category/${single?.node?.slug}`}
                className="button_container"
              >
                <p>{single?.node?.viewName}</p>
              </Link>
            </div>
          ))}
        </div>
        <div className="contact_privacy_box">
          <div className="contact">
            <Link to={"/contact"}>
              <p>お問い合わせ</p>
            </Link>
          </div>
          <div className="privacy">
            <Link to={"/privacypolicy"}>
              <p>プライバシーポリシー</p>
            </Link>
          </div>
          <div className="antena">
            <h3>リンク</h3>
            <a href="https://inunekolove.atna.jp/" target={"blank"}>
              ペット動物大好きアンテナ
            </a>
          </div>
        </div>

        {/* copy_write_div */}
        <div className="copy_write_div">
          <p>©2022animal</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
