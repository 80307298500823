import React from "react";
import MobileNav from "./nav";
import { Link } from 'gatsby'
const Nav = ({ headerText, data, records }) => {
  console.log("data", data)

  return (
    <div>
      {/* header_main_div */}
      <div className="header_main_div ">
        {/* header_content_div */}
        <div className=" header_content_div ">
          {/* image_text_div */}
          <div className="image_text_div">
            {/* text_div */}
            <div className="text_div">
              <h3>{headerText === "show" && "あにまるフレンズ"}</h3>
            </div>
          </div>
          {/* button_text_div */}
          <div className="thumbnail_text_div" >
            <Link to={`${data?.allFeedGatsbyBlog?.edges[10]?.node?.link}`}>
              {/* rss_thumbnail */}
              <div style={{display:'flex' , justifyContent:'space-between'}}>
                <div

                  className="rss_thumbnail_common_box"
                  style={{
                    backgroundImage:
                      "url(" +
                      data?.allFeedGatsbyBlog?.edges[10]?.node?.contentSnippet?.split(
                        "\n"
                      )[0] +
                      ")",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: "255px",
                    height: "95px",
                  }}
                >
                  {/* <p>Rss<br />Thumbnail</p> */}
                </div>

                {/* rss_text_div */}
                <div className="rss_text_div">
                  <p>{data?.allFeedGatsbyBlog?.edges[10]?.node?.title}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        {/* header_content_for_responsive */}
        <MobileNav data={data} records={records} />
      </div>
    </div >
  );
};

export default Nav;
