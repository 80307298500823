import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Header from "./header";
import SideNav from "./sidenav";
import Footer from "../components/footer";

const Layout = ({ children, seo, headerText }) => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName
        }
        allStrapiCategory {
          edges {
            node {
              slug
              name
              viewName
            }
          }
        }
        allStrapiPost {
          edges {
            node {
              title
              slug
              thumbnail
              category {
                viewName
                name
              }
            }
          }
        }
        allFeedGatsbyBlog {
          edges {
            node {
              title
              link
              content
              contentSnippet
            }
          }
        }
        feedGatsbyBlog {
          title
          link
          content
          contentSnippet
        }
      }
    `}
    // query={graphql`
    //   query {
    //     strapiGlobal(defalutSeo: {MetaDescription: {}, MetaTitle: {}}, children: {}) {
    //       siteName
    //       defalutSeo {
    //         MetaDescription
    //         MetaTitle
    //       }
    //     }
    //   }
    // `}
    render={(data) => (
      <div className="layout_container">
        <div className="layout_center_content">
          <SideNav data={data} records={data} />
          <div className="right_side_layout">
            <Header headerText={headerText} data={data} records={data} />
            <div className="children_conainer">
              {children}
              <Footer data={data} />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
