import React, { useState } from "react";
import Paw from "../images/paw.png";
import Menu from "../images/menu.png";
import CrossMark from "../images/cross.png";
import { Link } from "gatsby";

const Nav = ({ data, records }) => {
  const [mobileNav, setMobileNav] = useState(false);
  return (
    <div>
      {/* header_main_div */}
      <div className="header_main_div ">
        {/* header_content_for_responsive */}
        <div className="header_content_for_responsive">
          {/* image_hanmburger_div */}
          <div className=" image_hanmburger_div">
            {/* image_text_div */}
            <div className="image_text_div">
              {/* image_div */}
              <Link to={"/"}>
                <div className=" image_div ">
                  <img src={Paw} alt="" />
                </div>
              </Link>
              {/* text_div */}
              <div className=" text_div ">
                <p>Animal</p>
              </div>
            </div>
            {/* hamburger_div */}
            <div className="hamburger_div" onClick={() => setMobileNav(true)}>
              <img src={Menu} alt="" />
            </div>
          </div>
        </div>
        {/* nav_bar_menu_for_responsive */}
        {mobileNav && (
          <div className="nav_bar_menu_for_responsive">
            {/* background_color_div */}
            <div className="background_color_div">
              {/* nav_bar_main_content */}
              <div className="nav_bar_main_div">
                {/* nav_bar_content_flex_div */}
                <div className="nav_bar_content_flex_div">
                  {/* nav_bar_content */}
                  <div className="nav_bar_content">
                    {/* image_div */}
                    <div
                      className="image_div "
                      onClick={() => setMobileNav(false)}
                    >
                      <img src={CrossMark} alt="" />
                    </div>
                    {/* rss_text_div */}
                    <div className=" rss_text_div">
                      {/* rss_thumbnail */}
                      <div
                        className="rss_thumbnail_div"
                        style={{
                          backgroundImage:
                            "url(" +
                            data?.allFeedGatsbyBlog?.edges[10]?.node?.contentSnippet?.split(
                              "\n"
                            )[0] +
                            ")",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          height: "85px",
                        }}
                      ></div>
                      {/* rss_text_div */}
                      <div className="rss_text_div">
                        <p>{data?.allFeedGatsbyBlog?.edges[10]?.node?.title}</p>
                      </div>
                    </div>
                    {/* category_button_div*/}
                    {data?.allStrapiCategory?.edges?.map((single) => (
                      <div className="category_button_div">
                        <Link
                          to={`/category/${single?.node?.slug}`}
                          className="button_container"
                        >
                          <button>{single?.node?.viewName}</button>
                        </Link>
                      </div>
                    ))}

                    {/* ads_main_flex_div */}
                    <div className="ads_main_flex_div">
                      {/* ads_main_div */}
                      <a
                        href={data?.allFeedGatsbyBlog?.edges[15]?.node?.link}
                        target="_blank"
                        className="sideNav_Rss"
                      >
                        <div
                          className="ads_main_div"
                          style={{
                            backgroundImage:
                              "url(" +
                              data?.allFeedGatsbyBlog?.edges[15]?.node?.contentSnippet?.split(
                                "\n"
                              )[0] +
                              ")",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            display: "flex",
                            alignItems: "flex-end",
                            minHeight: "230px",
                            width: "85%",
                          }}
                        >
                          <div className="common_transparent_color">
                            <p>
                              {data?.allFeedGatsbyBlog?.edges[15]?.node?.title}
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default Nav;
